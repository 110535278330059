.modal-dialog {
    @extend .ageo-semibold;
}

.modal-content {
    width: 487px;
}

.modal-header {
    display: flex;
    justify-content: space-between; /* Ensures content stays spaced out */
    align-items: center; /* Vertically centers header elements */
    position: relative;
  }
  
  .modal-header .btn-close {
    position: absolute;
    top: 0; /* Aligns the button to the top */
    right: 0; /* Aligns the button to the right */
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: 0; /* Removes negative margins */
    left:225px;
  }
  

/* screen width below 500px */
@media (max-width: 500px) {
    .modal-content {
        width: auto !important;
    }
}

@media (max-width: 500px) {
    .toast-msg {
        width: 250px !important;
    }
}