.form-label {
  font-size: 16px;
  color: $gray-color;
  @extend .ageo-semibold;
}

.help-text {
  text-align: left !important;
}

textarea {
  resize: none;
}
/*To hide default password eye Icon in edge*/
input[type="password"] {
  &::-ms-reveal {
    display: none;
  }
}

// Kept for future reference in case of any issues
// .buttons {
//   width: 100% !important;
//   width: 100%;
// }

.success-modal {
  color: $danger-color;
  align-items: center !important;
}

.form-control:focus {
  border-color: $primary-color;
  @include box-shadow(none);
}

textarea {
  resize: none;
}

textarea {
  resize: none;
}

.form-select,
.form-control,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: $light-gray;
  height: 56px;
  color: $gray-color;
  font-family: "ageo-semibold", sans-serif;
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: $primary-color;
  @include box-shadow(none);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: $light-gray;
  background-image: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.form-select {
  &:focus {
    border-color: $primary-color;
    @include box-shadow(none);
  }
}

/* invalid-feedback */

.form-inline-text {
  margin-left: 5px;
}

.form-inline-feedback {
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
}

.invalid-feedback {
  display: block;
  color: $danger-color;
  text-align: left;
  font-size: 16px;
  @extend .ageo-medium;

  svg {
    width: 21px;
    position: relative;
    top: -2px;
  }
}

.invalid-feedback-small {
  color: $danger-color;
  text-align: left;
  font-size: 16px;
  @extend .ageo-regular;
  font-style: italic;

  svg {
    width: 21px;
    position: relative;
    top: -1px;
  }
}

.password-input {
  border: 1px solid $skrag-brown-color;
  border-radius: var(--bs-border-radius);
}

.form-group.vessel_info_1 {
  input {
    border: 2px solid #9E8959;
    background-color: #F1EEE6;
    color: #495057;
    padding: 10px;
    border-radius: 4px;
    border-width: 4px;
  }
}

.form-group.additional-vessel-type {
  input {
    border: 2px solid #A971B0;
    background-color: #F2EAF3;
    color: #495057;
    padding: 10px;
    border-radius: 4px;
    border-width: 4px;
  }
}


.server-error {
  color: $danger-color;
  @extend .ageo-medium;
  font-size: 16px;

  svg {
    width: 21px;
    position: relative;
    top: -3px;
    margin-right: 5px;
  }
}

.patient-record-form .server-error{
  text-align: center;
}

.asterisk {
  color: $danger-color;
}

/* common-form */
.common-form {
  * {
    letter-spacing: 0.5px;
    outline: none;
    border: none;
  }

  label {
    display: block;
    margin-top: 15px;
    font-size: 24px;
    font-weight: 500;
    color: $gray-color;
    @extend .ageo-bold;
  }

  input {
    border: 1px solid $skrag-brown-color;
    display: block;
    height: 58px;
    width: 100%;
    background-color: $white-color;
    @include border-radius(8px);
    padding: 0 15px;
    margin-top: 4px;
    font-size: 24px;
    font-weight: 300;
    color: $black-color;
    font-family: "ageo-semibold", sans-serif;
  }
}

/* manage-form */
.manage-form {
  .form-group {
    margin: 0 0 20px 0;
  }
}

/* form-wrapper */
.form-wrapper {
  .form-group {
    margin: 0 0 15px 0;

    .form-label {
      font-size: 16px;
      @extend .ageo-medium;
      color: $gray-color;
      margin: 0 0 5px 0;
    }

    .form-control,
    .form-select {
      font-size: 16px;
      font-family: "ageo-semibold", sans-serif;
      color: $gray-color;
      padding: 0 16px;

      &::placeholder {
        color: $chinese-silver;
      }
    }

    .btn {
      min-height: 56px;
      font-size: 18px;
      @extend .ageo-medium;
    }
  }

  .password-note {
    padding: 0 15px;

    ul {
      margin: 10px 0 0 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        color: $light-gray;
        padding: 0 15px 0 0;
      }
    }
  }
}

/* patient-record-form */
.patient-record-form {
  h2 {
    font-size: 24px;
    @extend .ageo-semibold;
    margin: 0 0 45px 0;
    color: $gray-color;
  }

  .birth-date-field {
    input[placeholder] {
      text-transform: uppercase !important;
      color: $chinese-silver !important;
    }
  }

  .form-group {
    margin: 0 0 54px 0;
    width: 100%;

    .form-label {
      margin: 0 0 4px 0;
    }

    /* location-dropdown */
    .location-dropdown {
      .css-13cymwt-control,
      .css-t3ipsp-control {
        height: 56px !important;
        border-color: $skrag-brown-color;
        @include border-radius(6px);

        .css-1u9des2-indicatorSeparator {
          display: none;
        }

        * {
          font-size: 18px !important;
          @extend .ageo-semibold;
          color: $gray-color;
        }

        .css-qbdosj-Input {
          font-size: 18px;

          input {
            font-size: 18px !important;
          }
        }
        svg {
          display: none;
        }
      }
    }

    .form-control {
      font-size: 16px;
      font-family: "ageo-semibold", sans-serif;

      &::placeholder {
        color: $chinese-silver !important;
      }
    }
  }

  .btn-primary {
    font-size: 18px;
    text-transform: uppercase;
    @extend .ageo-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;

    span {
      margin: 0 10px 0 0;
    }

    .icon {
      margin: 0 5px 0 0;
    }

    svg {
      position: relative;
      top: -1px;
    }
  }
}

@media (min-width: 1280px) {
  .custom-test-confirmation-grid {
    .row {
      .col-md-3 {
        &:first-of-type {
          flex: 0 0 auto;
          width: 30%;
        }

        &:nth-of-type(2) {
          flex: 0 0 auto;
          width: 20%;
        }

        &:nth-of-type(3) {
          flex: 0 0 auto;
          width: 20%;
        }

        &:nth-of-type(4) {
          flex: 0 0 auto;
          width: 30%;
        }
      }
    }

    .end-buttons {
      .col-md-3 {
        width: 140px !important;
      }
    }
  }
}

.confirm-details-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  min-height: 150px;

  button {
    min-width: 130px;
    min-height: 56px;
    width: auto !important;
    margin: 0 8px !important;

    &:last-of-type {
      margin-right: 0 !important;
    }
  }
}

.notes-textarea {
  textarea {
    width: 460px;
    height: 150px;
  }

  ::placeholder {
    color: $chinese-silver !important;
  }
}

.custom-test-confirmation-grid {
  padding: 24px 0 0 0;

  .confirm-details-buttons {
    min-height: 10px;
    padding-top: 75px;
  }

  .end-buttons {
    .col-md-3 {
      width: 140px !important;
    }
  }
}

/* enter-barcode-wrapper */
.enter-barcode-wrapper {
  .form-label {
    font-family: "ageo-semibold", sans-serif;
    font-size: 18px;
    width: 100%;
  }

  .test-type-field {
    margin: 0 0 25px 0;
    max-width: 330px;
    width: 100%;
  }

  .form-text {
    font-size: 18px;

    p {
      font-size: 18px;
    }
  }

  .help-text-above {
    color: $gray-color;

    .underline-text {
      font-family: "ageo-semibold", sans-serif;

      .danger-text {
        position: relative;

        &::after {
          background-color: $danger-color;
          content: "";
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 1px;
          z-index: 2;
        }
      }
    }
  }

  .underline-text {
    font-family: "ageo-semibold", sans-serif;

    .danger-text {
      position: relative;

      &::after {
        background-color: $danger-color;
        content: "";
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 1px;
        z-index: 2;
      }
    }
  }

  .barcode-group {
    .form-group {
      // display: flex;
      // flex-direction: column;

      .form-label {
        min-height: 36px;
        font-family: "ageo-semibold", sans-serif;
        color: $gray-color;
      }
    }
  }

  .barcode-tubes {
    margin: 0 0 45px 0;

    input[type="text"] {
      max-width: 330px;
      width: 100%;
    }
  }

  .birth-date-field {
    margin: 0 0 23px 0;
    max-width: 195px;
    width: 100%;

    .form-label {
      margin: 0 0 10px 0 !important;
    }

    .form-group {
      margin: 0;
    }
  }

  .time-field {
    margin: 0 0 23px 0;
    width: 166px;

    .form-group {
      margin: 0;
      max-width: 166px;
      width: 100%;

      .form-label {
        margin: 0 0 10px 0 !important;
      }
    }
  }

  .attempts-field {
    margin: 0 0 23px 0;
    max-width: 180px;
    width: 100%;

    .form-group {
      margin: 0;

      .form-select {
        max-width: 100px;
        width: 100%;
      }

      .form-label {
        margin: 0 0 10px 0 !important;
      }
    }
  }

  /* date-time-attempts */
  h2.date-time-attempts-title {
    font-size: 18px;
    margin: 0 0 10px 0 !important;
    @extend .ageo-regular;
  }

  .date-col {
    min-width: 170px;
    width: auto;
  }

  .time-col {
    min-width: 140px;
    width: auto;
  }

  .attempts-col {
    min-width: 160px;
    width: auto;

    .form-select {
      width: 100px;
    }
  }
}

.vessel-type-container {
  display: flex;
  flex-direction: column;
}

.additional-vessel-type {
  margin-top: 10px;
  /* Adjust as needed */
}

.test-registration-description {
  font-size: 18px;
  @extend .ageo-semibold;
  margin: 0 0 25px 0;
  color: $gray-color;
}

.table-key {
  font-family: Ageo, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}

/* responsive CSS */
/* screen width below 991px */
@media (max-width: 991px) {
  .form-wrapper {
    .password-note {
      ul {
        li {
          width: 100%;
        }
      }
    }
  }
}

.highlights {
  color: $primary-color !important;
}

.patient-record-form .rejection-select {
  margin: 0;
}
