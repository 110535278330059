.logo {
  height: 50px;
}

/* sidebar-container */
.sidebar-container {
  .sidebar {
    background: $whitest-white-color;

    @include box-shadow(0 4px 8px 0 rgba(0, 0, 0, 0.25));

    min-width: 220px;
    height: 100%;
    padding: 0 40px 0 30px;
    position: relative;
    left: -11px;

    .navbar-brand {
      margin: 117px 0 70px;
      text-align: center;
      max-width: 147px;
      height: 50px;
      display: flex;
    }

    .navbar-collapse {
      margin: 0 0 35px;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      height: calc(100vh - 687px);

      .navbar-nav {
        .nav-item {
          margin: 0 0 10px;

          .nav-link {
            color: $gray-color;
            font-size: 16px;

            @extend .ageo-semibold;

            padding: 0 0 0 40px;
            position: relative;
            min-height: 38px;
            display: flex;
            align-items: center;

            i {
              position: absolute;
              left: 0;
              top: 3px;
              font-size: 36px;
            }

            .nav-icon {
              position: absolute;
              left: 0;
              top: 0;
              display: flex;
              align-items: center;
              height: 38px;
            }

            &:hover,
            &.active {
              color: $primary-color;

              img {
                filter: invert(61%) sepia(56%) saturate(630%) hue-rotate(316deg)
                  brightness(100%) contrast(92%);
              }
            }
          }

          &:nth-child(even) {
            border-bottom: 1px solid $skrag-brown-color;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

/* profile-dropdown */
.profile-dropdown {
  width: 42px;
  height: 42px;

  @include border-radius(50%);

  background: $gray-color !important;
  border: solid 1px $gray-color !important;
  color: $white-color;

  @extend .ageo-bold;

  font-size: 21px;
  padding: 5px;

  &:hover {
    background: $primary-color;
    color: $white-color;
  }

  &::after {
    display: none;
  }
}

/* dropdown-menu */
.dropdown-menu {
  .dropdown-item {
    &:hover {
      background: none;
      color: $primary-color;
    }
  }
}

/* navbar-toggler */
.navbar-toggler {
  display: none !important;
}

/* logout-btn */
.logout-btn {
  @include border-radius(6px);

  width: 140px;
  height: 46px;
  margin: 220px 0 0;
  border: 1px solid $gray-color;
  background: $whitest-white-color;
  text-align: center;
  text-decoration: none;
  color: $gray-color;
  display: inline-block;

  .icon {
    display: none;
  }

  span {
    display: block;
    width: 100%;
  }

  &:hover {
    border-color: $primary-color;
    background: $white-color;
    color: $gray-color;
  }
}

.position-sticky {
  height: calc(100vh - 200px);
  position: initial !important;
}

.add-phlebotomist-admin-button {
  background-color: $text-color;
  border-color: transparent;
}

.messages {
  p {
    margin: 0;

    &.error {
      color: $error-color !important;
    }

    &.success {
      color: $error-color !important;
    }
  }
}

/* profile-dropdown-wrapper */
.profile-dropdown-wrapper {
  display: flex;

  * {
    box-sizing: border-box;
  }

  .profile-color {
    &.dropdown {
      display: flex;
      align-items: center;

      button {
        width: auto;
        padding: 0;

        &:focus,
        &:active,
        &:visited,
        &:focus-visible,
        &:focus-within {
          border-color: $white-color;
          outline: none;

          @include box-shadow(none);
        }

        &:hover {
          img {
            filter: invert(76%) sepia(88%) saturate(1980%) hue-rotate(311deg)
              brightness(102%) contrast(101%);
          }
        }

        &.btn-profile {
          width: 42px;
          height: 42px;
          margin: 0 0 0 15px;

          &:hover {
            background: $primary-color !important;
          }
        }
      }

      /* dropdown-menu */
      .dropdown-menu {
        left: auto;
        right: 0;
        width: 160px;

        a {
          position: relative;
          font-weight: 300;
          padding: 5px 15px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-of-type,
          &:nth-of-type(3),
          &:nth-of-type(4) {
            padding: 5px 15px 20px;

            &::after {
              content: "";
              background: $light-silver;
              width: calc(100% - 20px);
              height: 1px;
              position: absolute;
              left: 10px;
              bottom: 8px;
            }
          }
        }
      }
    }
  }

  .notification-dropdown {
    .dropdown-menu {
      a {
        &.dropdown-item {
          white-space: normal;
          text-overflow: unset;
        }
      }
    }
  }

  .dropdown-menu {
    li {
      .dropdown-item {
        .profile-circle {
          background: $light-silver;
          color: $white-color;
          font-size: 14px;
          font-weight: bold;
          width: 25px;
          height: 25px;
          display: inline-block;
          vertical-align: middle;

          @include border-radius(50%);

          line-height: 28px;
          text-align: center;
        }
      }

      &.border-bt {
        position: relative;
        padding: 0 0 15px;

        &::after {
          content: "";
          background: $light-silver;
          width: calc(100% - 20px);
          height: 1px;
          position: absolute;
          left: 10px;
          bottom: 8px;
        }
      }
    }
  }
}

/* responsive CSS */

/* screen width below 991px */
@media (max-width: 991px) {
  .sidebar-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100% !important;
    background: $white-color;
    min-height: 90px;
    z-index: 10;

    .sidebar {
      position: absolute !important;
      left: 0;
      z-index: 2;
      width: 100%;
      height: auto !important;

      @include box-shadow(none);

      padding: 0 15px;
      background: none !important;

      .navbar {
        background: none !important;

        .container {
          display: flex !important;
          max-width: 100%;
          position: relative;

          .navbar-brand {
            margin: 0;
            position: absolute;
            left: 0;
            top: 10px;

            img {
              max-width: 117px;
            }
          }

          .navbar-collapse {
            display: none;
            position: absolute;
            right: 0;
            top: 70px;
            background: #fff;
            padding: 15px;

            @include box-shadow(0 15px 30px rgba(30, 10, 58, 0.1));

            transition:
              all 0.5s ease-in-out 0s,
              opacity 0.5s ease-in-out;

            &.show {
              display: block;
              height: calc(100vh - 80px);
            }
          }

          .logout-btn {
            width: auto;
            position: absolute;
            right: 70px;
            top: 24px;
            height: 30px;
            padding: 5px 10px;
            margin: 0;
            line-height: 30px;
            display: flex;
            align-items: center;
          }
        }
      }

      .navbar-toggler {
        display: block !important;
        width: 46px;
        height: 46px;
        padding: 10px;
        background-color: $white-color;
        position: absolute;
        top: 15px;
        right: 0;

        &:focus {
          @include box-shadow(none);
        }

        .navbar-toggler-icon {
          background-image: none !important;
          background-color: $black-color;
          width: 25px;
          height: 2px;
          top: -3px;
          position: relative;
          color: $primary-color;

          &::before {
            position: absolute;
            content: "";
            width: 25px;
            height: 2px;
            margin-top: -12px;
            background-color: $black-color;
            display: block;
          }

          &::after {
            position: absolute;
            content: "";
            width: 25px;
            height: 2px;
            margin-top: 12px;
            background-color: $black-color;
            display: block;
          }
        }
      }
    }
  }

  #sidebarMenu.show {
    display: block !important;
  }
}

/* screen width above 992px */
@media (min-width: 992px) {
  .sidebar-container {
    min-width: 220px;
    max-width: 220px;
    min-height: 50px;
  }
}

/* screen width below 767px */
@media (max-width: 767px) {
  .main-content {
    flex: 1;
    padding: 93px 15px 15px !important;

    h1 {
      font-size: 25px;
      margin: 0 0 15px;
      top: 18px;
    }
  }

  .page-header {
    margin: 0 0 30px !important;

    h1 {
      font-size: 25px !important;
    }
  }

  .sidebar {
    .navbar-brand {
      margin: 57px 0 30px;
      text-align: center;
    }

    .nav {
      height: 100%;
    }
  }

  .logout-btn {
    margin: 0;
  }
}

/* screen width below 480x */
@media (max-width: 400px) {
  .btn-import-phlebotomists {
    width: 100%;
  }

  .btn-add-new-phlebotomist {
    width: 100%;
  }
}
