/* browser Prefixes */
@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin border-radius($property) {
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  -ms-border-radius: $property;
  -o-border-radius: $property;
  border-radius: $property;
}

@mixin text-size-adjust($property) {
  -webkit-text-size-adjust: $property;
  -moz-text-size-adjust: $property;
  -ms-text-size-adjust: $property;
  -o-text-size-adjust: $property;
  text-size-adjust: $property;
}

@mixin box-sizing($property) {
  -webkit-box-sizing: $property;
  -moz-box-sizing: $property;
  -ms-box-sizing: $property;
  -o-box-sizing: $property;
  box-sizing: $property;
}

@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -moz-box-shadow: $property;
  -ms-box-shadow: $property;
  -o-box-shadow: $property;
  box-shadow: $property;
}
