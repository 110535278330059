/* ageo-regular */
@font-face {
  font-family: ageo-regular;
  src: url("../fonts/Ageo-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

/* ageo-medium */
@font-face {
  font-family: ageo-medium;
  src: url("../fonts/Ageo-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

/* ageo-semibold */
@font-face {
  font-family: ageo-semibold;
  src: url("../fonts/Ageo-SemiBold.otf");
  font-weight: normal;
  font-style: normal;
}

/* ageo-bold" */
@font-face {
  font-family: ageo-bold;
  src: url("../fonts/Ageo-Bold.otf");
  font-weight: normal;
  font-style: normal;
}

/* ulm-grotesk-regular */
@font-face {
  font-family: ulm-grotesk-regular;
  src: url("../fonts/UlmGrotesk-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
