/* color */
$primary-color: #f48474;
$secondary-color: #1b5a42;

$danger-color: #ad3b73;
$error-color: #f00;

$black-color: #000;
$gray-color: #555;
$light-gray: #b9b9b9;
$chinese-silver: #ccc;
$light-silver: #d9d9d9;
$dark-green: #0a4237;

$white-color: #fff;
$offwhite-color: #f5f5f5;

$olive-color: #d4ded6;
$background-color: #f2f4f1;

$cardinal-red-color: #9c325d;
$skrag-brown-color: #b9b9b9;
$whitest-white-color: #f9f9f6;
$pinball-color: #d3d3d3;

$shadow-color: rgba(0, 0, 0, 20%);

$text-color: #0a4237;

$anti-flash-white: #f0f0f0;
$vivid-tangerine: #f7a087;
$silver-sand: #c6c6c6;
$grey-lilac-color: #d4cdcd;
$yellow-color: #eebf64;
$powder-pink-color: #e9cdcc;
$mint-color: #b7ccbc;
$dark-green-color: #003032;
$raspberry-color: #ad3b73;

/* border */
$border-color: $gray-color;

$card-background: rgba(255, 255, 255, 90%);
$background-image: url("../images/scientific-pattern.png");

/* width */
$full-width: 100%;

/* height */
$full-height: 100%;
