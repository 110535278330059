.login-bg {
  background-image: $background-image;
  background-size: cover;
}

/* login-form */
.login-form {
  background-color: $background-color;

  .card {
    @include box-shadow(0 4px 8px 0 $shadow-color);

    transition: 0.3s;

    @include border-radius(8px);

    padding: 95px 120px;
    max-width: 743px;
    width: 100%;
    margin: 20px;
    box-sizing: border-box;
    background-color: $card-background;

    .logo {
      max-width: 234px;
      width: 100%;
      height: auto;
      display: inherit;
      margin: 0 auto 74px;
    }

    .card-body {
      padding: 0;

      h2 {
        @extend .ageo-regular;

        font-size: 36px;
        color: $text-color;
        margin: 0 auto 10px;
        text-align: center;
      }

      p {
        font-size: 18px;
        color: $gray-color;
        margin: 0 0 20px;
        padding: 0;

        &.text-shadow {
          text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        }

        a {
          color: $gray-color;

          &:hover {
            text-decoration: none;
            color: $primary-color;
          }
        }
      }

      .form-text {
        margin: 5px 0 0;
        text-align: right;
        display: block;
        font-size: 18px;

        a {
          text-decoration: underline;

          @extend .ageo-medium;

          color: $gray-color;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .form-group {
        .form-label {
          font-size: 18px;

          @extend .ageo-semibold;
        }

        .btn-primary {
          max-width: 500px;
          width: 100%;
          margin: 15px 0 0 !important;
          font-size: 18px;

          @extend .ageo-bold;
        }
      }
    }
  }
}

/* login-page-container */
.login-page-container {
  h2 {
    margin: 0 auto 70px !important;
  }
}

/* responsive CSS */

/* screen width below 767px */
@media (max-width: 767px) {
  .login-form {
    .card {
      padding: 25px;

      .logo {
        max-width: 174px;
      }

      .card-body {
        h2 {
          font-size: 25px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
}

/* screen width below 700px */
@media (max-width: 700px) {
  .login-form {
    .card {
      padding: 40px;
    }

    .btn-olive,
    .btn-offwhite {
      max-width: 100%;
    }
  }
}

/* screen width below 520px */
@media (max-width: 520px) {
  .login-form {
    .card {
      padding: 40px;

      p {
        margin: 40px 0;
      }

      .btn-olive,
      .btn-offwhite {
        max-width: 100%;
        height: 60px;
        font-size: 18px;
        margin: 0 0 35px;
        padding-left: 65px;

        .icon {
          width: 52px;
          height: 52px;
          line-height: 52px;
          left: 5px;
          top: 5px;

          img {
            max-width: 30px;
          }
        }
      }
    }
  }
}

/* screen width below 420px */
@media (max-width: 420px) {
  .login-form {
    .card {
      padding: 35px;

      .logo {
        max-width: 150px;
      }
    }

    .card p {
      margin: 30px 0;
      font-size: 28px;
    }

    .btn-olive,
    .btn-offwhite {
      font-size: 16px;
      padding: 0 25px 0 75px;
      line-height: 20px;
    }
  }
}
