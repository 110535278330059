body {
  font-family:
    ageo-regular,
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    "Segoe UI",
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  margin: 0;
  padding: 0;
  color: $gray-color;
  font-size: 16px;
}

html {
  height: 100%;
  margin: 0;
}

* {
  @include text-size-adjust(100%);
  @include box-sizing(border-box);

  &::after {
    @include box-sizing(border-box);
  }

  &::before {
    @include box-sizing(border-box);
  }
}

*:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

img {
  max-width: 100%;
  height: auto;
}

/* font */

/* ageo */
.ageo-regular {
  font-family: ageo-regular, sans-serif;
}

.ageo-medium {
  font-family: ageo-medium, sans-serif;
}

.ageo-semibold {
  font-family: ageo-semibold, sans-serif;
}

.ageo-bold {
  font-family: ageo-bold, sans-serif;
}

/* ulm-grotesk-regular */
.ulm-grotesk-regular {
  font-family: ulm-grotesk-regular, sans-serif;
}

/* gothic-a1-regular */
.gothic-a1 {
  font-family: "Gothic A1", sans-serif;
}

/* background color */
.primary-bg {
  background: $primary-color;
}

.secondary-bg {
  background: $secondary-color;
}

/* text color */
.primary-text {
  color: $primary-color;
}

.secondary-text {
  color: $secondary-color;
}

.dark-grey-text {
  color: $gray-color;
}

.danger-text {
  color: $danger-color;
}

.height-100 {
  height: 100vh;
}

.underline-text {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background: $gray-color;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

/* container */
.container {
  width: 100%;
  max-width: 100%;
}
