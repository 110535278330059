.tablehead {
  text-align: center;
}

/* manage-store-location-table */
.manage-store-location-table {
  @include border-radius(10px);

  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  margin: 0;

  th {
    background: $light-silver;
    vertical-align: middle;
    
    @extend .ageo-bold;
  }

  td {
    vertical-align: middle;
  }

  .action-col {
    min-width: 150px;
    max-width: 150px;
    width: 150px;

    .btn {
      background: none;
      border: none;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .sr-col {
    min-width: 50px;
    text-align: center !important;
  }

  .firstname-col {
    min-width: 100px;
  }

  .lastname-col {
    min-width: 150px;
  }

  .email-col {
    min-width: 150px;
  }
}

.non-editable {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 1 !important;
}

/* responsive CSS */
