/* main-content */
.main-content {
  flex: 1;
  padding: 109px 128px 25px 40px;

  h1 {
    color: $gray-color;

    @extend .ulm-grotesk-regular;

    font-size: 33px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    top: 8px;
    position: relative;

    .navbar-brand {
      font-size: 36px;
      color: $gray-color;
    }
  }

  .form-title {
    font-size: 24px;

    @extend .ageo-medium;

    color: $gray-color;
    margin: 0 0 45px;
  }
}

/* page-header */
.page-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 25px;
  padding: 0 0 23px;
  border-bottom: 1px solid $chinese-silver;

  .btn-toolbar {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

/* step-links */
.step-links {
  ul {
    &.pagination {
      @include border-radius(5px);

      border: 1px solid $gray-color;
      overflow: hidden;

      li {
        &.page-item {
          &:first-child {
            a {
              &.page-link {
                border-left: none;

                &:hover {
                  border-left: none;
                }
              }
            }
          }
        }

        a {
          &.page-link {
            background: $white-color;

            @include border-radius(0);

            border-top: 0;
            border-bottom: 0;
            border-left: 1px solid $gray-color;
            border-right: 1px solid $gray-color;
            color: $gray-color;

            &:hover {
              color: $primary-color;
              border-left: 1px solid $gray-color;
              border-right: 1px solid $gray-color;
            }
          }

          &.active {
            background: $gray-color;
            color: $white-color;
            border-color: $gray-color;

            @include border-radius(0);
          }
        }
      }
    }
  }
}

/* toaster-message-container */
.toaster-message-container {
  padding: 15px;
  border: 1px solid rgba($black-color, 0.25);
  max-width: 300px;
  width: 100%;
  position: absolute;
  right: 10px;
  top: 10px;
  background: $white-color;
  z-index: 100;

  h2 {
    font-size: 15px;

    @extend .ageo-medium;
  }

  p {
    margin: 0;
  }
}

/* layout container */
.layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 100vh;
}

.sr-only {
  display: none;
}

.page-link:focus {
  box-shadow: none !important;
}

/* patient-details-page */
.patient-details-page {
  .patient-record-form {
    .form-group {
      margin: 0 0 25px;
      width: 100%;
    }
  }

  .divider {
    margin: 0 0 35px;
  }

  .react-bootstrap-table {
    width: 100%;
    overflow: auto;
    margin: 0 0 20px;

    .table {
      border-color: $light-gray;
      border-collapse: collapse;

      thead {
        tr {
          th {
            width: 150px;
            max-width: 150px;
            min-width: 150px;
            background: $anti-flash-white;
            font-size: 20px;

            @extend .ageo-semibold;

            &:nth-of-type(1) {
              border-radius: 4px 0 0;
              border-top-left-radius: 6px;
            }

            &:nth-of-type(5) {
              border-radius: 0 4px 0 0;
            }
          }
        }
      }

      tbody {
        tr {
          &.registered {
            td {
              color: $vivid-tangerine;
              font-size: 20px;

              @extend .ageo-semibold;
            }
          }

          td {
            word-wrap: break-word;
            vertical-align: middle;
            min-width: 240px;
            max-width: 240px;

            @extend .ageo-semibold;

            color: $gray-color;

            .btn {
              width: 100%;
              height: 46px;
            }
          }
        }
      }

      .text-filter {
        height: 46px;
        padding: 8px 10px 3px;
        font-size: 18px;
      }
    }
  }

  .react-bootstrap-table-pagination {
    .react-bootstrap-table-pagination-list {
      .pagination {
        .page-item {
          .page-link {
            height: 46px;
            display: flex;
            align-items: center;
            border: 1px solid #6b6b6b !important;
            color: #000;
          }
        }

        .active {
          .page-link {
            background: $gray-color;
            color: $white-color;
          }
        }
      }
    }
  }

  #page-drop-down {
    display: none;
  }
}

/* appointment-confirmation-table */
.appointment-confirmation-table {
  .react-bootstrap-table {
    margin: 0 0 10px;

    .table {
      border: 1px solid $light-gray;

      @include border-radius(5px);

      tr {
        td {
          min-height: 40px;
          height: 40px;
          vertical-align: middle;
          font-size: 18px;
          color: $gray-color;

          @extend .ageo-semibold;

          padding: 10px 25px;
          word-wrap: break-word;

          &:nth-child(even) {
            @extend .ageo-regular;

            font-size: 18px;
          }
        }
      }
    }
  }
}

/* test confirmation */
.test-confirmation-form {
  .form-group {
    margin: 0 0 14px;
    width: 100%;
  }
}

/* appointment-rejection */
.appointment-rejection {
  .rejection-select {
    padding: 10px 0 0 10px;
  }
}

/* responsive CSS */

/* screen width below 1199px */
@media (max-width: 1199px) {
  .main-content {
    padding: 109px 15px 25px;
  }
}

@media (max-width: 767px) {
  .appointment-confirmation-table {
    .react-bootstrap-table {
      .table {
        thead {
          tr {
            display: none;
          }
        }

        tbody {
          tr {
            display: block;
            width: 100%;

            td {
              display: block;
              width: 100%;
              height: auto;
              min-height: 10px;
            }
          }
        }
      }
    }
  }
}