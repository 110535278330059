/* mixin for button styles */
@mixin button-style($background-color, $border-color) {
  background-color: $background-color;
  border-color: $border-color;
  border: none;
  cursor: pointer;
  color: $white-color;

  &:hover,
  &:focus,
  &:active {
    color: $white-color !important;
    background-color: darken($background-color, 10%) !important;
  }
}

/* mixin for button size */
@mixin button-size($padding, $width) {
  padding: $padding;
  width: $width;
}

/* button colors and styles using maps */
$button-styles: (
  primary: (
    $primary-color,
    $primary-color,
  ),

  secondary: (
    $dark-green,
    $dark-green,
  ),

  danger: (
    $danger-color,
    $danger-color,
  ),

  gray: (
    $gray-color,
    $gray-color,
  ),

  outline: (
    $white-color,
    $gray-color,
  ),

  olive: (
    $olive-color,
    $olive-color,
  ),

  offwhite: (
    $offwhite-color,
    $offwhite-color,
  ),

  light-gray: (
    $light-gray,
    $light-gray,
  ),
  yellow: (
    $yellow-color,
    $yellow-color,
  ),
  grey-lilac: (
    $grey-lilac-color,
    $grey-lilac-color,
  ),
  powder-pink: (
    $powder-pink-color,
    $powder-pink-color,
  ),
  dark-green: (
    $dark-green-color,
    $dark-green-color,
  ),
  raspberry: (
    $raspberry-color,
    $raspberry-color,
  ),
  mint: (
    $mint-color,
    $mint-color,
  ),
);

/* button styles using nesting and variables */
.btn {
  @each $variant, $colors in $button-styles {
    @extend .ageo-semibold;
    &--#{$variant} {
      @if $variant == "outline" {
        background-color: transparent;
        border: 1px solid nth($colors, 2);
        color: nth($colors, 2);

        &:hover {
          background-color: darken(nth($colors, 1), 10%);
          color: darken(nth($colors, 2), 10%);
          border-color: $border-color;
        }
      } @else if $variant == "olive" {
        @include button-style(nth($colors, 1), nth($colors, 2));
        border-radius: 50px;
      } @else {
        @include button-style(nth($colors, 1), nth($colors, 2));
      }
    }
  }

  /* button sizes using mixins */
  &--small {
    @include button-size(10px 16px, 150px);
  }

  &--medium {
    @include button-size(11px 20px, 250px);
  }

  &--large {
    @include button-size(12px 24px, 488px);
  }
}

/* btn-primary */
.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;

  &:hover {
    background: $skrag-brown-color !important;
    border-color: $skrag-brown-color !important;
    color: $white-color !important;
  }

  &:focus {
    background: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color !important;
  }
}

/* .btn-reject */
.btn-reject {
  background: $danger-color !important;
  border-color: $danger-color !important;
  color: $white-color !important;

  &:hover {
    background: $skrag-brown-color !important;
    border-color: $skrag-brown-color !important;
    color: $white-color !important;
  }

  &:focus {
    background: $skrag-brown-color !important;
    border-color: $skrag-brown-color !important;
    color: $white-color !important;
  }
}

/* icon-validation */
.icon-validation {
  color: $error-color;
  font-size: smaller;
  padding: 10px 0 0 0;
}

/* btn--olive */
.btn-olive {
  color: $gray-color;
  background: $olive-color;
  @include border-radius(50px);
  width: 100%;
  height: 97px;
  margin: 0 0 51px 0;
  padding: 0 0 0 100px;
  position: relative;
  @include box-shadow(0 2px 6px 0 $shadow-color);
  font-size: 24px;
  @extend .ageo-semibold;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    background: $white-color;
    width: 82px;
    height: 82px;
    @include border-radius(50px);
    line-height: 77px;
    position: absolute;
    left: 8px;
    top: 8px;

    img {
      width: 49px;
    }
  }

  &:hover {
    color: $gray-color !important;
  }
}

/* btn-offwhite */
.btn-offwhite {
  color: $gray-color;
  @include border-radius(50px);
  width: 100%;
  height: 97px;
  position: relative;
  @include box-shadow(0 2px 6px 0 $shadow-color);
  font-size: 24px;
  @extend .ageo-semibold;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    background: $white-color;
    width: 82px;
    height: 82px;
    @include border-radius(50px);
    line-height: 77px;
    position: absolute;
    left: 8px;
    top: 8px;

    img {
      width: 49px;
    }
  }

  &:hover {
    color: $gray-color !important;
  }
}

/* btn-profile */
.btn-profile {
  border: none;
  background-color: $gray-color;
  color: $white-color;
  width: 50px;
  height: 50px;
  @include border-radius(50px);

  &:hover,
  &:active,
  &:focus {
    color: $white-color;
  }
}

.btn-dropdown {
  color: red($color: $black-color);
  background-color: $black-color;
}

/* dropdown-toggle */
.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

/* profile-circle */
.profile-circle {
  background: $light-silver;
  color: $white-color;
  font-size: 14px;
  @extend .ageo-bold;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  @include border-radius(50%);
  line-height: 28px;
  text-align: center;
  margin: 0 5px 0 0;
}

/* gray-btn */
.gray-btn {
  background: $primary-color;
  border: 0;

  &:hover {
    background: $primary-color;
    border: 0;
  }
}

/* btn  */
.btn {
  &.btn-danger {
    background-color: $danger-color;
    border-color: $danger-color;
    color: $white-color;

    &:hover {
      background-color: $cardinal-red-color;
      border-color: $cardinal-red-color;
      color: $white-color;
    }
  }
}

/* btn-notification */
.btn-notification {
  border: none;
  background-color: $white-color !important;
  color: $white-color;
  width: 50px;
  height: 50px;
  @include border-radius(50px);
}

.custom-button {
  margin: 0 !important;
  padding: 7px;
  bottom: auto !important;
}

.login-btn {
  width: 100%;
  max-width: 488px;
  margin: 0 !important;
}

/* disable hover CSS*/
.no-hover {
  pointer-events: none;
  cursor: default;
}

/* responsive CSS */

@media (max-width: 767px) {
  .form-wrapper {
    .form-group {
      .btn {
        min-height: 10px !important;
        font-size: 16px !important;
      }
    }
  }
}
