.custom-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  max-width: 70rem;
  margin-right: 0;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  padding: 0 !important;

  .rounded-circle {
    border-radius: 0 !important;
  }
}

.logo-image {
  width: 40px;
  height: 20px;
}

.custom-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px !important;
}

.card-text-container {
  flex-grow: 1;
  padding: 0 20px 0 0;

  @extend .ulm-grotesk-regular;

  color: $secondary-color;
  display: flex;
  flex-direction: column;
}

.custom-card-button {
  flex-shrink: 0;
}

/* test-confirmation-card */
.test-confirmation-page {
  h2 {
    font-size: 20px;
    margin: 0 0 15px;
    color: $gray-color;

    @extend .ageo-semibold;
  }

  h3 {
    font-size: 18px;
    margin: 0 0 20px;
    color: $gray-color;
    font-weight: normal;
    @extend .ageo-regular;
  }

  .test-confirmation-col {
    min-width: 145px;

    .test-confirmation-card {
      border: 1px solid $silver-sand;
      padding: 10px;
      margin: 0 0 15px;
      @include border-radius(10px);
      height: calc(100% - 20px);
      cursor: pointer;

      .step-number {
        display: inline-block;
        width: 23px;
        height: 23px;
        font-size: 16px;

        @extend .ageo-semibold;

        border: 1px solid $vivid-tangerine;

        @include border-radius(50%);

        text-align: center;
        color: $vivid-tangerine;
        margin: 0 0 10px;
      }

      .card-body {
        padding: 0;
        text-align: center;

        p {
          font-size: 16px;
          line-height: 22px;
        }

        .step-img {
          margin: 0 auto 20px;
          text-align: center;
          min-height: 104px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }

      &.active-card {
        border: 1px solid $vivid-tangerine;
        cursor: default;
      }
    }
  }
}

/* responsive CSS */

@media (max-width: 767px) {
  .test-confirmation-page {
    .test-confirmation-col {
      .test-confirmation-card {
        min-height: 10px;
      }
    }
  }
}
